const refs = {
    bodyEl: $("body"),
    burgerButton: $('.hamburger'),
    burgerMenu: $('.burger'),
    burgerLinks: $('.burger .nav-list > .menu-item-has-children'),
    headerSearch: $('.header-search'),
    searchForm: $('.search-form'),
    searchInput: $('.search-input'),
    orderButtons: $('.order-list__button'),
    orderSelect: $('select.toolbar-els__button'),
    productsList: $('.products-list'),
    productsItems: $('.products-items'),
    paginationContainer: $('.pagination-container'),
    filterContainer: $('.filter-container'),
    productsNav: $('.products-nav'),
    toolbarFilter: $('.toolbar-filter'),
    currentFilter: $('.current-filter'),
    selectContainer: $('.select-container'),
    navWrapper: $('.nav-wrapper'),
    breadCrumbCurrent: $('.breadcrumb .current'),
    toolbarTitle: $('.toolbar-wrapper__title'),
    orderButtonText: $('.order-button__text'),
    orderList: $('.order-list'),
    optionsList: $('.options-list'),
    optionsButtons: $('.options-list__button'),
    authBackdrop: $('#auth'),
    authButton: $('.auth-button-js'),
    registerForm: $('#register-form'),
    loginForm: $('#login-form'),
    authModal: $('.auth'),
    formSwitcher: $('.form-switcher'),
    accountNav: $('#account .nav-list'),
    personalForm: $('.personal-form'),
    passwordForm: $('.password-form'),
    passVisibilityButton: $('.form-field__button'),
    orderListButtons: $('.orders-list__button'),
    orderInfoWrapper: $('.order-info'),
    headerLink: $('.header .menu-item-has-children'),
    vacanciesButtons: $('.vacancies-list__button'),
    formModal: $('#form-modal'),
    formModalTitle: $('.form-modal__title'),
    formModalForm: $('#form-modal form'),
    hideFormModalButton: $('.form-modal__close'),
    submitForm: $('.form-js'),
    heroButtonToGiftSet: $('[data-taxonomy]')
};
export default refs;
